import {MdPhotoLibrary} from 'react-icons/md'
import {useSelector} from 'react-redux'
import {classNames, lang} from '@/components/common/Helpers'
import GuestbookReplyItem from './GuestbookReplyItem'
import {useState} from 'react'
import ReactLinkify from 'react-linkify'

import moment from 'moment'

export default function GuestbookItem({ post, className, setObituaryPost }) {

    const { obituary } = useSelector((state) => state.obituaryReducer)
    const [showAllReplies, setShowAllReplies] = useState(false)

    const language = lang

    const toggleShowAllReplies = () => {
        setShowAllReplies((prevShowAllReplies) => !prevShowAllReplies)
    }

    const visibleReplies = showAllReplies ? post.posts : post.posts.slice(0, 2)

    return (
        <div className={classNames("bg-white rounded-md overflow-hidden", className)}>
            {(post.obituary_assets?.length > 0 && obituary) && (
                <div className="relative cursor-pointer" onClick={() => setObituaryPost(post)}>
                    <img loading='lazy' src={post.obituary_assets[0].asset.thumbnail} className="w-full object-cover max-h-80" alt={`Guestbook gallery photo 1: ${obituary.display_name} posted by ${post.name}`} />
                    <div className="absolute bottom-2 right-2 w-12 h-12 bg-black/50 rounded-full text-white flex items-center justify-center">
                        <MdPhotoLibrary className="w-6 h-6" />
                    </div>
                </div>
            )}

            <div className="text-sm p-6 leading-6">
                {post.has_candle == true &&
                    <img loading='lazy' src={`${import.meta.env.VITE_APP_URL}/img/guestbook/candles.svg`} className="w-20 h-20 float-left mb-2 mr-4" />
                }
                <p className="whitespace-pre-wrap">
                    <ReactLinkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a
                                href={decoratedHref}
                                className="text-blue-500 break-all"
                                target="_blank"
                                rel="noopener"
                                key={key}
                            >
                                {decoratedText}
                            </a>
                        )}
                    >
                        {post.formatted_message}
                    </ReactLinkify>
                </p>
            </div>
            <div className="px-6 pb-6 text-sm">
                <p className="text-blue-800 uppercase font-bold">{post.name}</p>
                <p className="text-gray-400">{moment(post.submitted_at).toDate().toLocaleDateString(language, {
                    day: '2-digit',
                    year: 'numeric',
                    month: 'short',
                })}</p>
            </div>

            {post.posts?.length > 0 && (
                <div className={classNames("border-t p-6 bg-gray-200")}>
                    <p className="text-xs text-gray-800 font-bold pb-2">
                        {post.posts.length} {post.posts.length === 1 ? "Reply" : "Replies"}:
                    </p>

                    {visibleReplies.map(childPost =>
                        <GuestbookReplyItem
                            key={childPost.id}
                            post={childPost}
                            setObituaryPost={setObituaryPost}
                        />
                    )}
                    {post.posts.length > 2 && (
                        <button
                            onClick={toggleShowAllReplies}
                            className="bg-grey-100 text-blue-500 my-2 ml-4 text-xs hover:text-blue-600"
                        >
                            {showAllReplies ? "Show Less Replies" : "Show All Replies"}
                        </button>
                    )}

                </div>
            )}
        </div>
    )
}
